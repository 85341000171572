import React, {useState} from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';
import { GoogleLogin } from 'react-google-login';
import AWS from 'aws-sdk'
import API from '@aws-amplify/api';
import Amplify from 'aws-amplify'

function App() {
  const [credentials, setCredentials] = useState();

  const loginSuccess = (response) => {
    console.log(response);

    setCredentials(response)

    fetch(`${window.PUBLIC_URL}/frontend-config.json`)
      .then(res => res.json())
      .then(config => {
         console.log(config)

         AWS.config.region = 'eu-west-1';

         AWS.config.credentials = new AWS.WebIdentityCredentials({
           RoleArn: config['role_arn'],
           WebIdentityToken: response.getAuthResponse().id_token
         });

         AWS.config.credentials.get();

        setTimeout(() => {
            Amplify.configure({
                    API: {
                        endpoints: [{
                            name: 'my_api',
                            endpoint: config['api_endpoint'],
                        }]
                    },
                    credentials: new AWS.WebIdentityCredentials({
                                            RoleArn: config['role_arn'],
                                            WebIdentityToken: response.getAuthResponse().id_token
                                          })
                });

                 API.get("my_api", "/example", {}).then(response => {
                   console.log(response);
                 });
        }, 1000)

      });
  }

  const loginFailure = (response) => {
    console.log(response);
  }

  return (
    <div className="App">
      <header className="App-header">
        <GoogleLogin
          clientId="218535343931-3b1dkjoo5jdnuvlckokfif372e690ur8.apps.googleusercontent.com"
          buttonText={credentials ? credentials.getBasicProfile().getName() : "Login"}
          onSuccess={loginSuccess}
          onFailure={loginFailure}
          isSignedIn={true}
          cookiePolicy={'single_host_origin'}
        />
      </header>
    </div>
  );
}

export default App;
